<template>
    <div class="card placeholder">
        <content-placeholders>
            <div class="card__inner">
                <content-placeholders-heading :img="true" />
                <content-placeholders-img v-if="showImage" />
                <div class="card__content" v-if="!showImage">
                    <content-placeholders-text :lines="numLines" />
                </div>
                <div class="card-ctas-wrap">
                    <div class="placeholder-icons">
                        <div class="vue-content-placeholders-heading__img"></div>
                        <div class="vue-content-placeholders-heading__img"></div>
                    </div>
                    <div class="card__social-from vue-content-placeholders-heading__img"></div>
                </div>
                <div class="card__content" v-if="showImage">
                    <content-placeholders-text :lines="numLines" />
                </div>
            </div>
        </content-placeholders>
    </div>
</template>

<style>
.vue-content-placeholders-heading {
    border-bottom: 1px solid #f3f3f3;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 12px;
}
.vue-content-placeholders-heading__img {
    border-radius: 6px;
    height: 44px;
    width: 44px;
}
.vue-content-placeholders-heading__title {
    background: #eee;
    width: 45%;
}
.vue-content-placeholders-heading__subtitle {
    background: #ccc;
    width: 70%;
}
.vue-content-placeholders-img {
    margin: 0 !important;
    height: 178px;
}
.big .vue-content-placeholders-img {
    height: 350px;
}
.placeholder-icons {
    display: grid;
    grid-template-columns: 32px 32px 1fr;
}
.placeholder .card__inner .card__content + .card-ctas-wrap {
    margin-bottom: 16px;
}
.card-ctas-wrap .vue-content-placeholders-heading__img {
    height: 24px;
    margin: 0;
    width: 24px;
}
.card-ctas-wrap.footer {
    border-top: 1px solid #f3f3f3;
    margin: 0 12px 24px;
    padding-top: 12px;
}
.placeholder .card__content {
    background-color: #fff;
    border-radius: 12px;
    padding: 16px 16px 6px;
}
</style>

<script>
import MasonryStore from "../configs/masonry-store";

export default {
    data() {
        return {
            numLines: 3,
            lines: [3, 4, 5],
            showImage: true
        };
    },
    created() {
        this.showImage = Math.random() < 0.5;
        this.numLines = this.lines[Math.floor(Math.random() * this.lines.length)];
    }
}
</script>
