var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card placeholder" },
    [
      _c("content-placeholders", [
        _c(
          "div",
          { staticClass: "card__inner" },
          [
            _c("content-placeholders-heading", { attrs: { img: true } }),
            _vm._v(" "),
            _vm.showImage ? _c("content-placeholders-img") : _vm._e(),
            _vm._v(" "),
            !_vm.showImage
              ? _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _c("content-placeholders-text", {
                      attrs: { lines: _vm.numLines },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "card-ctas-wrap" }, [
              _c("div", { staticClass: "placeholder-icons" }, [
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vue-content-placeholders-heading__img",
                }),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "card__social-from vue-content-placeholders-heading__img",
              }),
            ]),
            _vm._v(" "),
            _vm.showImage
              ? _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _c("content-placeholders-text", {
                      attrs: { lines: _vm.numLines },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }